import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Suspense, lazy } from 'react';
const Home = lazy(() => import('./components/Home'));
function App() {
  return (
      <Router>
        <div className='app-main'>
          <Suspense>
            <Routes>
              <Route path='/' element={<Home />} />
            </Routes>
          </Suspense>
        </div>
      </Router>

  );
}

export default App;
